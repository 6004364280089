import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';
import { useQuery } from '@tanstack/react-query';

import { getTimetable, getTimetableData } from '../calls';

export const useGetTimetable = (id?: string) => {
  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.TIMETABLE, id as string],
    queryFn: getTimetable,
    enabled: !!id,
  });

  return result;
};

export const useGetTimetableData = (id?: string) => {
  const result = useQuery({
    queryKey: [QUERY_KEY.TIMETABLE_DATA, id as string],
    queryFn: () => getTimetableData(id as string),
    enabled: !!id,
  });

  return result;
};
