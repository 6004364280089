import { getPlatform } from '@analytics/Analytics';
import { documentReferrer } from '@analytics/GoogleAnalytics';
import airbridge from 'airbridge-web-sdk-loader';
import { db } from 'firebase-config';
import { EventNameString } from 'firebase/analytics';
import { addDoc, collection } from 'firebase/firestore';
import { flatten } from 'flat';
import { distanceBetween } from 'geofire-common';
import { isEmpty } from 'lodash-es';
import posthog from 'posthog-js';
import { v4 as uuidv4 } from 'uuid';

import { isAppApproaching } from '@utils/Common';

import { AnalyticsParams } from './params';
import { SendAirbridgeEvent } from './send-airbridge-event';

const GA_MAX_LOCATION_LENGTH = 500;
const GA_MAX_REFERRER_LENGTH = 300;
const GA_MAX_TITLE_LENGTH = 100;

const sessionKey = 'DT'; // 암호화 키
// XOR 암호화 함수
export function xorEncrypt(data: string) {
  const encoded = [];
  for (let i = 0; i < data.length; i++) {
    encoded.push(data.charCodeAt(i) ^ sessionKey.charCodeAt(i % sessionKey.length));
  }
  return encoded.map((code) => String.fromCharCode(code)).join('');
}

// // XOR 복호화 함수 (암호화와 동일)
// function xorDecrypt(encryptedData, key) {
//   return xorEncrypt(encryptedData, key); // 암호화와 동일한 방식
// }

export const sessionId = () => {
  if (typeof window === 'undefined') return '';
  if (localStorage.getItem('dt-session-id')) return localStorage.getItem('dt-session-id');

  const id = uuidv4();

  localStorage.setItem('dt-session-id', id);

  return id;
};

/**
 * undefined를 지우는 함수.
 * https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
 */
const removeUndefined = (obj: any) => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeUndefined(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

/**
 * string literal과 string을 혼합하여 자동완성이 되게 하려면 다음과 같이 선언해야 함.
 * https://github.com/Microsoft/TypeScript/issues/29729#issuecomment-832522611
 */
type LiteralUnion<T extends U, U = string> = T | (U & Record<never, never>);

const is사무실 = (location: LocationType) => {
  const distance = distanceBetween(
    [location.latitude, location.longitude],
    [37.5473111143411, 126.94978478260782],
  );

  // 사무실보다 100m 이내에 있으면 사무실로 판단
  if (distance < 0.1) return true;

  return false;
};

export interface BaseParams {
  user?: User | null;
  location?: LocationType | null;
  pathname?: string;
}

/**
 *구매 전환, 전자상 거래 구매 관련 로그 이벤트들은 이름 앞에 $를 붙여줍니다.
 */
export class BaseLogEvent {
  protected isAppApproaching = false;
  protected user?: User | null = null;
  protected location?: LocationType | null = null;
  protected pathname = '';
  protected params = AnalyticsParams;
  protected posthog = posthog;

  constructor({ user, location, pathname }: BaseParams) {
    this.isAppApproaching =
      isAppApproaching() &&
      Boolean(window.flutter_inappwebview) &&
      Boolean(window.flutter_inappwebview.callHandler);

    this.user = user;
    this.location = location;
    this.pathname = pathname || '';
  }

  get isValid() {
    return (
      (this.user ? !this.user.isManager : true) && (this.location ? !is사무실(this.location) : true)
    );
  }

  public GAEvent(eventName: LiteralUnion<EventNameString>, eventParams?: Record<string, unknown>) {
    this.FlarelaneEvent.trackEvent(eventName, eventParams ? flatten(eventParams) : {});

    if (!this.isValid) return;

    // 전체 URL을 가져옵니다.
    const fullURL = document.location.href;

    // URL 객체를 생성합니다.
    const url = new URL(fullURL);

    // 도메인 부분을 추출합니다.
    const domain = url.origin;

    /**
     * GA에서 page_location의 길이 제한이 1000자여서 넘어갈 경우 GA가 아예 안찍히는 문제가 있습니다.
     * 따라서 SPA 에서 주로 사용하는 방법인 브라우저 history 기반으로 자동으로 page_view를 찍어주는 방식이 아닌,
     * router.pahname 변경 시에 직접 page_view를 찍어주는 방식으로 변경합니다.
     */
    window.gtag('event', eventName, {
      /**
       * GA에서 page_location의 길이 제한이 1000자여서 넘어갈 경우 GA가 아예 안찍히는 문제가 있습니다.
       */
      page_title: window.document.title.slice(0, GA_MAX_TITLE_LENGTH),
      content_group: this.pathname,
      page_location: domain + this.pathname,
      raw_page_location: fullURL.slice(0, GA_MAX_LOCATION_LENGTH),
      raw_page_pathname: window.location.pathname,
      platform: getPlatform(),
      document_referrer: document.referrer.slice(0, GA_MAX_REFERRER_LENGTH),
      is_member: Boolean(this.user),
      raw_user_id: this.user && this.user.uid ? this.user.uid : 'null',
      location: this.location
        ? xorEncrypt(`${this.location?.latitude},${this.location?.longitude}`)
        : 'null',
      // 기본 document.referral 대신 직접 페이지 레퍼러를 정의헤서 사용합니다. (처음은 제외)
      ...(!isEmpty(documentReferrer)
        ? { page_referrer: documentReferrer.slice(0, GA_MAX_REFERRER_LENGTH) }
        : {
            page_referrer: document.referrer.slice(0, GA_MAX_REFERRER_LENGTH),
          }),
      ...eventParams,
    });
    // window.gtag('event', eventName, {
    //   /**
    //    * GA에서 page_location의 길이 제한이 1000자여서 넘어갈 경우 GA가 아예 안찍히는 문제가 있습니다.
    //    */
    //   page_location: window.location.href.slice(0, GA_MAX_LOCATION_LENGTH),
    //   page_title: window.document.title.slice(0, GA_MAX_TITLE_LENGTH),
    //   ...eventParams,
    // });

    /**
     * GA 이벤트 이외에 따로 Firebase에 이벤트를 저장함.
     * BigQuery에서도 볼 수 있지만, 민감한 정보들 (ex : 유저, 위치) 등은 Analytics로 전송할 수 없기 때문에
     * Firebase에 저장함.
     */
    addDoc(collection(db, 'PageView'), {
      ...eventParams,
      eventName: eventName,
      location: this.location ?? null,
      user: this.user ? this.user.get() : null,
      session_id: sessionId(),
      device_token: window.deviceToken ?? null,
      is_noti_granted: window.isNotiGranted ?? 'undefined',
      date: new Date(),
      page_location: window.location.href,
      platform: window.navigator.userAgent,
      isApp: window.navigator.userAgent.includes('App'),
    });
  }

  public setUserProperty(property: object) {
    this.FlarelaneEvent.setTags(flatten(property));

    if (!window.gtag || !this.isValid) return;

    window.gtag('set', 'user_properties', {
      ...property,
    });

    posthog.capture('$set', {
      $set: {
        ...property,
      },
    });

    Object.entries(flatten(property) ?? {}).forEach(([key, value]) => {
      airbridge.setUserAttribute(key, value);
    });
  }

  /**
   * 기존에 존재하던 SendAirbridgeEvent를 그대로 사용함. 이벤트를 하나씩 추가할 때마다 수정이 필요함.
   * 추후 eventName, eventParams를 인자로 받아서 동적아면서, app - web 에서 모두 사용할 수 있도록 수정 필요
   * sendIndividual라는 이벤트로 이벤트 전송은 가능한 듯.
   */
  get AirbridgeEvent() {
    return new SendAirbridgeEvent({
      isValid: this.isValid,
      isAppApproaching: this.isAppApproaching,
    });
  }

  get NaverEvent() {
    return {
      pvEvent: () => {
        if (!window.wcs) return;
        if (window.wcs_add) {
          // (2) 각 사이트별 식별자 설정
          window.wcs_add['wa'] = 's_49d37ee25646'; // 사이트 식별자 (=네이버공통키, na_account_id)
        } else {
          window.wcs_add = {
            wa: 's_49d37ee25646',
          };
        }

        window.wcs.inflow();

        window.wcs_do();
      },
      trackEvent: ({
        type,
        items,
        id,
        value,
      }: {
        type: string;
        items: {
          id: string;
          name: string;
          category?: string;
          quantity: number;
          payAmount: number;
          option?: string;
        }[];
        id: string;
        value: string;
      }) => {
        if (!window.wcs) return;

        if (window.wcs_add) {
          // (2) 각 사이트별 식별자 설정
          window.wcs_add['wa'] = 's_49d37ee25646'; // 사이트 식별자 (=네이버공통키, na_account_id)
        } else {
          window.wcs_add = {
            wa: 's_49d37ee25646',
          };
        }

        window.wcs.inflow();

        window.wcs.trans({
          type,
          items,
          id,
          value,
        });
      },
    };
  }

  get FlarelaneEvent() {
    return {
      setUserID: (userID: string) => {
        if (this.isAppApproaching) {
          window.flutter_inappwebview?.callHandler('SetFlarelaneUserId', userID);
        } else {
          window.FlareLane?.setUserId(userID);
        }
      },
      trackEvent: async (type: string, data?: Record<string, string | number | Date | boolean>) => {
        const object = data
          ? (flatten(data) as Record<string, string | number | Date | boolean>)
          : {};
        const stringfiedData = Object.entries(object).reduce((acc, [key, value]) => {
          if (!value) return acc;

          if (typeof value === 'string') {
            acc[key] = value;
            return acc;
          }
          acc[key] = value?.toString(); // 로그인 후 터지는 버그가 있어서 옵셔널 붙임
          return acc;
        }, {} as Record<string, string>);

        if (this.isAppApproaching) {
          if (isEmpty(stringfiedData)) {
            window.flutter_inappwebview.callHandler('TrackFlarelaneEvent', type);
            return;
          }
          window.flutter_inappwebview.callHandler(
            'TrackFlarelaneEvent',
            type,
            JSON.stringify(stringfiedData),
          );
        } else {
          window.FlareLane?.trackEvent(type, stringfiedData);
        }
      },
      setTags: (tags: Record<string, string | number | Date | boolean>) => {
        const object = flatten(tags) as Record<string, string | number | Date | boolean>;
        const stringifiedTags = Object.entries(object).reduce((acc, [key, value]) => {
          if (typeof value === 'string') {
            acc[key] = value;
            return acc;
          }
          acc[key] = value?.toString(); // 로그인 후 터지는 버그가 있어서 옵셔널 붙임
          return acc;
        }, {} as Record<string, string>);

        if (this.isAppApproaching) {
          window.flutter_inappwebview.callHandler(
            'SetFlarelaneTags',
            JSON.stringify(stringifiedTags),
          );
        } else {
          window.FlareLane?.setTags(stringifiedTags);
        }
      },
    };
  }
}
