import { isAppApproaching } from './is-app-approaching';

export const getDeviceByUserAgent = (): Device | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const userAgent = navigator.userAgent;

  if (isAppApproaching({ isIOSApproaching: true })) {
    return 'IOS app';
  } else if (isAppApproaching({ isAndroidpproaching: true })) {
    return 'ANDROID app';
  } else if (userAgent.match(/iPhone|iPod/i) != null) {
    return 'IOS web';
  } else if (
    userAgent.match(
      /Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null ||
    userAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return 'ANDROID web';
  } else {
    return 'PC web';
  }
};
