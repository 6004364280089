import { getPlatform } from '@analytics/Analytics';
import AirbridgeAPI from 'airbridge-web-sdk-loader/build/airbridge_api';

import { BaseLogEvent, BaseParams } from '../BaseLogEvent';

export class 유입 extends BaseLogEvent {
  constructor(params: BaseParams) {
    super(params);
  }

  public $회원가입(option: AirbridgeAPI.SignOption, provider: Provider) {
    this.GAEvent('sign_up', {
      sign_up_provider: provider ?? '',
    });
    this.AirbridgeEvent.signUp({
      ...option,
      attributes: {
        ...option.attributes,
        provider,
      },
    });
    this.FlarelaneEvent.setTags({
      uid: option.userID ?? '',
      email: option.userEmail ?? '',
      phoneNum: option.userPhone ?? '',
      phone: option.userPhone ?? '',
    });
    this.posthog.capture('sign_up', {
      provider,
    });

    this.NaverEvent.trackEvent({
      type: 'sign_up',
      id: '',
      items: [],
      value: '',
    });
  }

  public $로그인(user: User) {
    const provider = user.provider;

    this.GAEvent('login', {
      login_provider: provider ?? '',
    });

    this.AirbridgeEvent.signIn({
      userID: user.uid,
      userEmail: user.email,
      userPhone: user.phoneNum,
      attributes: {
        userName: user.name,
        userId: user.uid,
        userEmail: user.email,
        userPhone: user.phoneNum,
        userRealName: user.realName,
        userBirthday: user.birthday,
        userNotificationAgreed: user.notificationAgreed,
        provider,
      },
    });
    this.posthog.capture('login', {
      provider,
    });
    if (user.uid) {
      this.FlarelaneEvent.setUserID(user.uid);
    }
    const lastOrder = (user.orders ?? []).length > 0 ? user.orders[user.orders.length - 1] : null;

    this.FlarelaneEvent.setTags({
      uid: user.uid,
      email: user.email,
      phoneNum: user.phoneNum,
      // E.164 format (+821011112222) 으로 변환
      phoneNumber: user.phoneNum.startsWith('+82') ? user.phoneNum : `+82${user.phoneNum.slice(1)}`,
      phone: user.phoneNum,
      name: user.realName ?? '고객',
      real_name: user.realName ?? '',
      birthday: user.birthday ?? '',
      dob: user.birthday && user.birthday.length >= 10 ? user.birthday : '',
      together_event: user.togetherEvent ? 'true' : 'false',
      notification_agreed: user.notificationAgreed ? 'true' : 'false',
      provider,
      ...(lastOrder
        ? {
            academy_name: lastOrder.receipt.academyName,
            academy_id: lastOrder.receipt.academyId,
            order_id: lastOrder.id,
            order_date: lastOrder.requestedAt?.toLocaleDateString(),
          }
        : {}),
    });

    if (window.gtag && user.uid) {
      window.gtag('config', 'G-HTBLW14T84', {
        user_id: user.uid,
        update: true,
      });

      this.setUserProperty({
        birthyear: user.birthday ? user.birthday.split('-')[0] : 'null',
      });
    }
  }

  public homeView() {
    this.AirbridgeEvent.homeViewEvent();
  }

  public signOut() {
    this.AirbridgeEvent.signOut();

    this.GAEvent('sign_out', {
      sign_out: 'sign_out',
    });
  }

  public univFunnel(screen: string) {
    this.GAEvent('univ_funnel', {
      screen,
    });
  }

  public univCouponReceive(univ_name: string, is_affiliate: boolean) {
    this.GAEvent('univ_coupon_receive', {
      univ_name: univ_name,
      is_affiliate,
    });
  }

  public univEventShare(univ_name: string) {
    this.GAEvent('univ_event_share', {
      univ_event_share: univ_name,
    });
  }

  public newbieEventShare() {
    this.GAEvent('newbie_event_share', {
      newbie_event_share: 'newbie_event_share',
    });
  }

  public newbieRecommendShare() {
    this.GAEvent('newbie_recommend_share', {
      newbie_recommend_share: 'newbie_recommend_share',
    });
  }

  public licenseCampShare() {
    this.GAEvent('dt_license_camp_share', {
      dt_license_camp_share: 'dt_license_camp_share',
    });
  }

  public pageView(option: Record<string, unknown>) {
    this.GAEvent('page_view', option);

    this.AirbridgeEvent.sendIndividual('page_view', {
      action: this.pathname ?? '',
      label: getPlatform(),
    });

    this.NaverEvent.pvEvent();
  }

  public newbieEventAction(action: '이벤트 참여하기') {
    this.GAEvent('newbie_event_action', {
      action,
    });
  }
  public go3ChickenAction(action: string) {
    this.GAEvent('go3_chicken_action', {
      action,
    });

    window.fbq('trackCustom', 'go3_chicken_action', { action });

    this.AirbridgeEvent.sendIndividual('go3_chicken_action', {
      action,
    });
    this.posthog.capture('go3_chicken_action', {
      action,
    });
  }
  public speedQuizAction(action: string) {
    this.GAEvent('speed_quiz_action', {
      action,
    });

    window.fbq('trackCustom', 'speed_quiz_action', { action });

    this.AirbridgeEvent.sendIndividual('speed_quiz_action', {
      action,
    });
    this.posthog.capture('speed_quiz_action', {
      action,
    });
  }
  public DTTipAction(action: string) {
    this.GAEvent('dt_tip_action', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('dt_tip_action', {
      action,
    });
    this.posthog.capture('dt_tip_action', {
      action,
    });
  }
  public gameAction(action: string) {
    this.GAEvent('game_action', {
      action,
    });

    window.fbq('trackCustom', 'game_action', { action });

    this.AirbridgeEvent.sendIndividual('game_action', {
      action,
    });
    this.posthog.capture('game_action', {
      action,
    });
  }
  public startGame() {
    this.GAEvent('start_game');

    this.AirbridgeEvent.sendIndividual('start_game');
    this.posthog.capture('start_game');
  }

  public exitGame(seconds: number) {
    this.GAEvent('exit_game', {
      seconds,
    });

    this.AirbridgeEvent.sendIndividual('exit_game', {
      label: seconds.toString(),
    });
    this.posthog.capture('exit_game', {
      label: seconds.toString(),
    });
  }

  public go3ChickenConversion() {
    this.GAEvent('go3_chicken_conversion', {
      action: 'go3_chicken_conversion',
    });
  }

  public knockCouponAction(action: string) {
    this.GAEvent('knock_action', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('knock_action', {
      action,
    });
    this.posthog.capture('knock_action', {
      action,
    });
  }

  public newbieRecommendAction(action: '친구 초대하기') {
    this.GAEvent('newbie_recommend_action', {
      action,
    });
  }

  public licenseCamp(action: '면허캠프') {
    this.GAEvent('dt_license_camp', {
      action,
    });
  }
  public licenseCampAction({ startDate, endDate }: { startDate: Date; endDate: Date }) {
    this.GAEvent('dt_license_camp_action', {
      startDate,
      endDate,
    });
  }

  // 웹투앱 노출에 대한 이벤트
  public webToApp(position: string) {
    this.GAEvent('web_to_app', {
      position,
    });

    this.AirbridgeEvent.sendIndividual('web_to_app', {
      label: position,
    });
  }
}
