import { atom } from 'jotai';

import { atomWithSuperJsonLocalStorage } from './storage';

export const loadingAtom = atom<boolean>(false);

export const rouletteEventModalAtom = atom<boolean>(false);

export const testFinishedAtom = atom<boolean>(false);

export const reservationFinishedAtom = atomWithSuperJsonLocalStorage<boolean>(
  'reservationFinished',
  false,
);

export const globalVisibleAtom = atomWithSuperJsonLocalStorage<{
  [key: string]: {
    maintainDate: Date | null;
    hideDate: Date | null;
  };
}>('globalVisible', {});

export const shuttleMapModalVisibleAtom = atom<boolean>(false);
